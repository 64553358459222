import React, { useMemo, useState } from 'react'
import { Button, Col, Row, Form, Input, Layout, Typography, Divider, Result } from 'antd'

const { Content } = Layout
const { Title, Text } = Typography

const App: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState<any>({ code: null, message: null })

  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: 'Per favore inserisci la tua ${name}'
  }

  const fetchUrl = process.env.REACT_APP_ENV === 'development' ? 'http://localhost:3000/' : '/'

  const onFinish = (values: {
    email: string
    password: string
  }): void => {
    setLoading(true)
    fetch(`${fetchUrl}api/v1/bot-this-creds`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
      .then(async (resp) => {
        const returnValue = await resp.json()
        if (!(resp.ok)) {
          throw new Error(returnValue.message)
        }
        setStatus({ code: 'success' })
      })
      .catch(({ message }) => {
        setStatus({ code: 'warning', message })
      })
      .finally(() => setLoading(false))
  }

  const onFinishFailed = (errorInfo: any): void => {
    console.log('Failed:', errorInfo)
  }

  const resultStatus = useMemo(() => {
    if (status.code === null) {
      return null
    }

    return status.code === 'success'
      ? {
          status: status.code,
          title: 'Abbiamo recuperato con successo i documenti presso il tuo Centro per l’impiego.'
        }
      : {
          status: status.code,
          title: status.message,
          subtitle: <Text>Non siamo riusciti a recuperare automaticamente i documenti – controlla le tue credenziali oppure procedi con la compilazione manuale <a href="https://link.mclgroup.it/anagrafica">clicca qui</a></Text>
        }
  }, [status])

  return (
    <Layout style={{
      backgroundColor: 'transparent',
      padding: '30px 15px'
    }}>
      <Row>
        <Col md={{ span: 24 }} xl={{ span: 12, offset: 6 }} style={{
          backgroundColor: 'white',
          padding: '20px 35px'
        }}>
          <Title level={4}>
            Reverso
          </Title>
          <Text style={{
            margin: 0,
            fontSize: 16
          }}>Recupero dei documenti presso il tuo centro per l’impiego in Regione Campania</Text>
          <Divider style={{
            margin: '8px 0 24px'
          }}/>
          <Content>
            <Row>
              <Text strong style={{
                margin: 0,
                fontSize: 16
              }}>Procedendo ci autorizzi ad utlizzare le tue credenziali SPID per accedere alla piattaforma di Regione Campania</Text>
            </Row>
            <Divider style={{
              marginBottom: '12px'
            }}/>
            <Row style={{
              textAlign: 'center',
              padding: '12px 0 24px'
            }}>
              <Col>
                <Text>Inserisci le tue credenziali <strong>SPID con Poste Italiane</strong> <br/>Hai un provider SPID diverso? <a href="https://form.jotform.com/210032088170342">clicca qui</a>
                </Text>
              </Col>
            </Row>
            <Row style={{
              padding: '0 0 25px 0'
            }}>
              <Col span={24}>
                {status.code !== null
                  ? <Result
                      {...resultStatus} extra={<Button onClick={() => setStatus({ code: null, message: null })} type="primary" key="console"> Torna indietro </Button>}/>
                  : <Form
                      validateMessages={validateMessages}
                      name="login"
                      wrapperCol={{ span: 24, offset: 0 }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
            >
                    <Text style={{
                      fontSize: 14,
                      textTransform: 'uppercase'
                    }}>Email</Text>
                    <Form.Item colon={false} name="email" rules={[{ required: true }]}>
                      <Input className='input--underlined' placeholder='Inserisci la tua email SPID'/>
                    </Form.Item>

                    <Row style={{
                      marginTop: 32
                    }}>
                      <Text style={{
                        fontSize: 14,
                        textTransform: 'uppercase'
                      }}>Password</Text>
                    </Row>
                    <Form.Item colon={false} name="password" rules={[{ required: true }]}>
                      <Input.Password className='input--underlined' placeholder='Inserisci la tua password SPID' />
                    </Form.Item>

                    <Form.Item style={{
                      paddingTop: 20
                    }} wrapperCol={{ offset: 0, span: 16 }}>
                      <Button type="primary" htmlType="submit" style={{
                        cursor: 'pointer'
                      }} loading={loading}>
                        {loading ? 'Controlla il tuo smartphone – App PosteID' : 'Accetto e procedo'}
                      </Button>
                    </Form.Item>
                  </Form>}
              </Col>
            </Row>
          </Content>
        </Col>
      </Row>
    </Layout>
  )
}

export default App
